<!--
 * @Descripttion: 我的订单页面组件
 * @Author: Banbri
 * @Date: 2021-07-03 18:01:05
 * @LastEditors: Banbri
 * @LastEditTime: 2021-06-09 09:39:36
--> 

<template>
  <div class="order" id="order" name="order">
    <div class="order-layout">
      <el-row :gutter="10">
        <div>
          <CenterMenu></CenterMenu>
        </div>
        <el-col :span="20">
          <div class="order-content">
            <div class="extra"></div>
            <!--我的订单头部-->
            <div class="order-title">
              <p>我的订单</p>
            </div>
            <div class="order-select">
              <router-link :to="{ path: '/order' }">
                <span :class="status == undefined ? 'select' : 'no-select'"
                  >全部有效订单</span
                >
              </router-link>
              <span class="cut">|</span>
              <router-link :to="{ path: '/order', query: { status: 0 } }">
                <span :class="status == 0 ? 'select' : 'no-select'">待支付</span>
              </router-link>
              <span class="cut">|</span>
              <router-link :to="{ path: '/order', query: { status: 1 } }">
                <span :class="status == 1 ? 'select' : 'no-select'">待发货</span>
              </router-link>
              <span class="cut">|</span>
              <router-link :to="{ path: '/order', query: { status: 2 } }">
                <span :class="status == 2 ? 'select' : 'no-select'">待收货</span>
              </router-link>
              <span class="cut">|</span>
              <router-link :to="{ path: '/order', query: { status: 3 } }">
                <span :class="status == 3 ? 'select' : 'no-select'">待评价</span>
              </router-link>
              <span class="cut">|</span>
              <router-link :to="{ path: '/order', query: { status: 4 } }">
                <span :class="status == 4 ? 'select' : 'no-select'">已评价</span>
              </router-link>
              <!-- <span class="no-select">订单回收站</span> -->
              <!-- <div class="search">
                <el-input placeholder="输入商品名称、订单号" v-model="search">
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="searchClick"
                  ></el-button>
                </el-input>
              </div> -->
            </div>
            <div v-if="orders.length > 0">
              <!--我的订单头部 end-->
              <!--订单列表-->
              <div
                class="order-list"
                v-for="(item, index) in orders"
                :key="index"
              >
                <div class="order-list-head">
                  <div class="order-pay" v-if="item.status == 0">待支付</div>
                  <div class="order-pay" v-if="item.status == 1">待发货</div>
                  <div class="order-pay" v-if="item.status == 2">待收货</div>
                  <div class="order-pay" v-if="item.status == 3">待评价</div>
                  <div class="order-pay" v-if="item.status == 4">已评价</div>
                  <div class="order-info">
                    <div style="width: 650px">
                      <span class="info">{{
                        item.created_at | dateFormat
                      }}</span>
                      <span class="cut">|</span>
                      <span class="info">{{ item.address_name }}</span>
                      <span class="cut">|</span>
                      <span class="info">订单号：{{ item.order_num }}</span>
                      <span class="cut">|</span>
                      <span class="info">在线支付</span>
                    </div>
                    <span class="info" style="margin-left: 30px"
                      >应付金额：</span
                    >
                    <span class="money">{{
                      item.discount_price * item.num
                    }}</span>
                    <span class="info">元</span>
                  </div>
                </div>
                <div class="order-list-product">
                  <div class="pro-img">
                    <router-link
                      :to="{
                        path: '/goods/details',
                        query: { productID: item.product_id }
                      }"
                    >
                      <img :src="$target + item.product_picture" />
                    </router-link>
                  </div>
                  <div class="pro-info">
                    <p style="margin-bottom: 7px">
                      <router-link
                        class="info-href"
                        :to="{
                          path: '/goods/details',
                          query: { productID: item.product_id }
                        }"
                        >{{ item.product_name }}</router-link
                      >
                    </p>
                    <span>{{ item.discount_price }}</span
                    >&nbsp;×
                    <span>{{ item.num }}</span>
                  </div>
                  <div class="operate">
                    <div v-if="item.status == 0">
                      <router-link
                        :to="{
                          path: '/payment',
                          query: { orderNum: item.order_num }
                        }"
                      >
                        <el-button class="button-pay">立即付款</el-button>
                      </router-link>
                    </div>
                    <div v-if="item.status == 2">
                      <el-button
                        status="warning"
                        class="button-pay"
                        @click="confirmReceice(item.order_num)"
                        >确认收货</el-button
                      >
                    </div>
                    <div v-if="item.status == 3">
                      <el-button
                        status="warning"
                        class="button-pay"
                        @click="comment(item.product_id,item.order_num)"
                        >评价</el-button
                      >
                    </div>
                    <div v-if="item.status == 4">
                      <el-button
                        @click="comment(item.product_id,item.order_num)"
                        >追加评价</el-button
                      >
                    </div>
                    <div>
                      <router-link
                        :to="{
                          path: '/order/details',
                          query: { orderNum: item.order_num }
                        }"
                      >
                        <el-button plain class="button-detail"
                          >订单详情</el-button
                        >
                      </router-link>
                    </div>
                    <div v-if="item.status == 1">
                      <router-link
                        :to="{
                          path: '/order/refund',
                          query: { orderNum: item.order_num }
                        }"
                      >
                        <el-button plain class="button-detail"
                          >申请退款</el-button
                        >
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 分页 -->
              <div class="pagination">
                <el-pagination
                  background
                  @current-change="handleCurrentChange"
                  :page-size="pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="total"
                ></el-pagination>
              </div>
              <div class="extra"></div>
              <div class="extra"></div>
            </div>
            <!-- 分页END -->
            <!-- 订单为空的时候显示的内容 -->
            <div v-else class="empty">
              <p>您还没有相关的订单</p>
              <p>可以去看看有哪些想买的</p>
            </div>
            <!-- 订单为空的时候显示的内容END -->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import CenterMenu from '../components/CenterMenu'
export default {
  name: 'Order',
  data() {
    return {
      orders: [], // 订单列表
      pageSize: 5,
      total: 0,
      start: 0,
      limit: 5,
      status: '',
      search: ''
    }
  },
  created() {
    this.status = undefined
    this.getOrders()
  },
  watch: {
    $route(to) {
      if(to.name === 'Order'){
        this.status = to.query.status
        this.start = 0
        this.getOrders()
      }
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.start = this.limit * (val - 1) // val 页面
      this.getOrders()
    },
    getOrders() {
      this.$axios
        .post('/api/user/order/getOrder', {
          user_id: this.$store.getters.getUser.user_id,
          status: this.status,
          start: this.start,
          limit: this.limit
        })
        .then((res) => {
          if (res.data.code === '001') {
            this.orders = res.data.orders
            this.total = res.data.total
          } else {
            // this.notifyError(res.data.msg)
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    comment(product_id,order_num) {
      this.$prompt('请输入评价', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        this.$axios
          .post('/api/user/comment/addComment', {
            user_id: this.$store.getters.getUser.user_id,
            product_id,
            comment_msg:value,
            order_num
          })
          .then((res) => {
            if (res.data.code == '001') {
              // 评价成功
              this.notifySucceed(res.data.msg)
            } else {
              // 评价失败
              this.notifyError(res.data.msg)
            }
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      })
    },
    confirmReceice(order_num){
      this.$confirm('确定确认收货吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$axios
          .post('/api/order/confirmReceive', {
            order_num
          })
          .then((res) => {
            if (res.data.code == '001') {
              // 确认收货成功
              this.notifySucceed(res.data.msg)
              this.getOrders()
            } else {
              // 确认收货失败
              this.notifyError(res.data.msg)
            }
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      })
    }
  },
  components: {
    CenterMenu
  }
}
</script>
<style scoped>
.order-layout {
  max-width: 1225px;
  margin: 0 auto;
}
.order-content {
  background-color: #ffffff;
  margin-bottom: 30px;
}
.order-title {
  height: 100px;
  display: flex;
  align-items: center;
}
.order-title p {
  font-size: 30px;
  color: #757575;
  margin-left: 50px;
}
.extra {
  height: 10px;
}
.order-select {
  width: 920px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.order-select .no-select {
  font-size: 17px;
  color: #757575;
  margin-right: 10px;
}
.order-select .select {
  font-size: 17px;
  color: #ff6700;
  margin-right: 10px;
}
.order-select .cut {
  font-size: 22px;
  color: #c9c7c7;
  margin-right: 15px;
  font-weight: 300;
}
.order-select .search {
  width: 300px;
  margin-left: 225px;
}
/*订单头部*/
.order-list-head {
  height: 100px;
  background-color: #fffaf7;
  width: 920px;
  margin: 0 auto;
  margin-top: 20px;
  border-left: 1px solid #ff6700;
  border-right: 1px solid #ff6700;
  border-top: 1px solid #ff6700;
  border-bottom: 1px solid #feccac;
}
.order-list-head .order-pay {
  font-size: 19px;
  color: #ff6700;
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.order-list-head .order-success-pay {
  font-size: 19px;
  color: #00a724;
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.order-list-head .order-info {
  width: 860px;
  margin: 0 auto;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.order-list-head .order-info .info {
  font-size: 15px;
  color: #757575;
}
.order-list-head .order-info .cut {
  font-size: 17px;
  color: #c9c7c7;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 300;
}
.order-list-head .order-info .money {
  font-size: 25px;
  color: #000000;
  margin-right: 5px;
  margin-left: 10px;
}
/*订单头部END*/
/*订单商品*/
.order-list-product {
  height: 130px;
  background-color: #ffffff;
  width: 920px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-left: 1px solid #ff6700;
  border-right: 1px solid #ff6700;
  border-bottom: 1px solid #ff6700;
}
.order-list-product .pro-img {
  margin-left: 30px;
  margin-right: 20px;
}
.order-list-product .pro-img img {
  height: 80px;
  width: 80px;
}
.order-list-product .pro-info {
  width: 500px;
  font-size: 15px;
  color: #333333;
}
.order-list-product .pro-info .info-href {
  font-size: 15px;
  color: #333333;
}
.order-list-product .pro-info .info-href:hover {
  color: #ff6700;
}
.order-list-product .operate {
  line-height: 50px;
  margin-left: 150px;
}
.order-list-product .operate .button-pay {
  width: 100px;
  color: #ffffff;
  background-color: #ff6700;
}

.order-list-product .operate .button-detail {
  width: 100px;
}
/*订单商品END*/
.order-content .pagination {
  width: 300px;
  margin: 0 auto;
  margin-top: 20px;
}
.empty {
  width: 200px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 60px;
  color: #bdbaba;
  font-size: 18px;
}
</style>